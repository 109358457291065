.label {
  @apply flex cursor-pointer;
}

.label > input[type='checkbox'] {
  @apply hidden;
}

.label > input[type='checkbox'] + span {
  @apply relative border border-[#B0B8C8] rounded-[2px] mr-2 flex-shrink-0 mt-[5px] bg-[#C9C9C9];
  width: 14px;
  height: 14px;
}

.label > input[type='checkbox'] + span::after {
  content: '';
  width: 10px;
  height: 8px;
  background-image: url('./tick.svg');
  background-size: contain;
  background-repeat: no-repeat;

  @apply absolute left-[1px] right-[2px] top-[3px];
}

.label > input[type='checkbox']:checked + span {
  @apply bg-purple border-purple;
}
