.box {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  overflow: auto;
  @apply bg-white z-20 rounded-lg max-tablet:top-[5%] max-tablet:left-[5%] max-tablet:right-[5%] max-tablet:max-h-[93%] max-tablet:transform-none max-tablet:!w-[90%];
}

.box-content {
  @apply p-8 pt-0 max-tablet:p-6 max-tablet:pt-0;
}
