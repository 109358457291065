/* .container {
  background: linear-gradient(
    147.13deg,
    rgba(254, 211, 104, 0.2) 0%,
    rgba(234, 98, 103, 0.2) 32%,
    rgba(71, 183, 241, 0.2) 63.5%,
    rgba(134, 117, 254, 0.2) 100%
  );
  @apply m-5 rounded-[0.875rem] pt-[100px] pb-[58px] max-[950px]:py-0 max-[950px]:bg-none max-[950px]:bg-[#D6DFF1] max-[950px]:rounded-md;
}

.container2 {
  @apply flex justify-center items-center max-[950px]:items-start max-[950px]:mb-6 max-[950px]:flex-col;
} */
.container {
  background: linear-gradient(
    147.13deg,
    rgba(254, 211, 104, 0.2) 0%,
    rgba(234, 98, 103, 0.2) 32%,
    rgba(71, 183, 241, 0.2) 63.5%,
    rgba(134, 117, 254, 0.2) 100%
  );

  @apply mx-5 pt-[100px] pb-[58px] max-[950px]:py-0 rounded-[0.875rem] flex flex-col max-[950px]:bg-none max-[950px]:bg-[#D6DFF1] max-[950px]:rounded-md max-[950px]:m-0 max-[950px]:mb-6;
}
/* .container {
  @apply m-5 rounded-[0.875rem] flex justify-center items-center max-[950px]:items-start max-[950px]:bg-none max-[950px]:bg-[#D6DFF1] max-[950px]:rounded-md max-[950px]:m-0 max-[950px]:mb-6 max-[950px]:flex-col;
} */
