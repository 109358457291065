@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-Satoshi text-font-black;
}

.rainbow {
  background: linear-gradient(
    270deg,
    rgb(255, 217, 104) 0%,
    rgb(230, 75, 102) 35.1351%,
    rgb(66, 187, 240) 67.1171%,
    rgb(139, 111, 255) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rainbow-reverse {
  background: linear-gradient(
    270deg,
    rgb(139, 111, 255) 0%,
    rgb(66, 187, 240) 35.1351%,
    rgb(230, 75, 102) 67.1171%,
    rgb(255, 217, 104) 100%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.legal-text {
  @apply space-y-6 text-[18px] leading-[1.5em];
}
.legal-text purple,
.legal-text strong,
.legal-text a {
  color: #7e5dff;
}

.legal-text ol {
  @apply list-decimal list-inside;
}

/* .legal-text ol li {
  @apply pl-3;
} */

.legal-text ul {
  @apply list-disc list-inside;
}
